import React from 'react';
import PageSpinner from 'components/PageSpinner';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { getTotal, getAvg } from 'utils/dataManager';

const ChartCard = ({ header, loading, error, children, values, height, description, hideAvg = false, hideTotal = false }) => {
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <div>{header}</div>
        <div className="d-flex">
          {!hideAvg && values && values.length && <div className="ml-3">avg: <b>{getAvg(values)}</b></div>}
          {!hideTotal && values && values.length && <div className="ml-3">total: <b>{getTotal(values)}</b></div>}
        </div>
      </CardHeader>
      <CardBody style={height && {"height" : height}}>
        {loading
          ? <PageSpinner />
          : error ? <Alert color="secondary">{error}</Alert> : children
        }
        {description && <p className="description">{description}</p>}
      </CardBody>
    </Card>
  )
}

export default ChartCard;