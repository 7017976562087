export const colors = {
  primary: '#6a82fb',
  secondary: '#fc5c7d',
  // Flat UI colors from https://materialui.co/flatuicolors/
  teal: '#1abc9c',
  darkteal: '#16a085',
  green: '#2ecc71',
  darkgreen: '#27ae60',
  lightblue: '#86E7E6',
  blue: '#3498db',
  darkblue: '#2980b9',
  purple: '#9b59b6',
  darkpurple: '#8e44ad',
  yellow: '#f1c40f',
  darkyellow: '#f39c12',
  orange: '#e67e22',
  darkorange: '#d35400',
  lightred: '#FA9696',
  red: '#e74c3c',
  darkred: '#c0392b',
  grey1: '#ecf0f1',
  grey2: '#bdc3c7',
  grey3: '#95a5a6',
  grey4: '#7f8c8d',
  grey5: '#34495e',
  grey6: '#2c3e50',
  babyblue: '#89CFF0',
  cornflowerblue: '#6495ED',
  glaucous: '#6082B6',
  coralpink: '#F88379',
  sunsetorange: '#FA5F55',
  vermillion: '#E34234',
  // Calmer Colors
  calm_lightyellow: '#ecb45c',
  calm_darkyellow: '#e6a54c',
  calm_lightred: '#f09884',
  calm_darkred: '#e2876d',
  calm_lightpink: '#e498a5',
  calm_darkpink: '#cd7c87',
  calm_lightpurple: '#b48dc0',
  calm_darkpurple: '#ab7bb5',
  calm_lightviolet: '#8c8cc4',
  calm_darkviolet: '#7572b4',
  calm_lightblue: '#21bdca',
  calm_darkblue: '#1da2b9',
  calm_lightgreen: '#5cc2b0',
  calm_darkgreen: '#23bb9f',
  // Color Variants
  variant_lightgreen: '#74cbbc',
  variant_lightblue: '#3fb0c3',
  variant_lightred: '#e69983',
  variant_lightyellow: '#eab267',
  variant_lightpurple: '#bf9ec9'
}


export const getColor = (availableColor = 'primary') => {
  if (typeof window === 'undefined') {
    return null;
  }

  const color = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${availableColor}`);

  return color;
};

export const getThemeColors = () => [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
];
