import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import Page from 'components/Page';
import ChartCard from 'components/Card/ChartCard';
import { HorizontalBar, Bar } from 'react-chartjs-2';
import { chartConfigMultiLinedStackedBar, setOptions, setOptionsBarMultiColored } from 'utils/chartConfigs';
import { colors } from 'utils/colors';
import {
  getDataSet,
  getSumDataSet,
  getFilteredData,
  getFilteredLabels,
  setDatesAsWeeks,
  setDatesAsMonths,
  getDataSetGrouped,
  groupBy,
  getTotal
} from 'utils/dataManager';

const STACKED = true;
const FILL = true;

const AnalysisDetailsPage = ({ analyticsData, errors }) => {
  const { analyticsEventsPerDay } = analyticsData;
  // const mockedData = {
  //   'snippet_ready': 9,
  //   'song_ready': 8,
  //   'chord_analysis_ready': 8,
  //   'recording_stop_too_short': 7,
  //   'analysis_result_missing': 6,
  //   'analysis_aborted_cloud_analysis_begin': 5,
  //   'analysis_aborted_cloud_upload_finish': 5,
  //   'analysis_aborted_cloud_analysis_end': 4,
  //   'analysis_aborted_snippet_ready': 3,
  //   'analysis_aborted_song_ready': 2,
  //   'analysis_aborted_chord_analysis_ready': 1
  // };

  // analyticsEventsPerDay.data = analyticsEventsPerDay.data.map(data => ({ ...data, ...mockedData }));

  const getDataFilteredAndGrouped = (filter, group) => {
    const analyticsEventsFiltered = getFilteredData(analyticsEventsPerDay, filter);
    let analyticsEventsGrouped = [];

    switch (group) {
      case 'week' :
        analyticsEventsGrouped = groupBy(setDatesAsWeeks(analyticsEventsFiltered), 'creation_date');
        break;
      case 'month' :
        analyticsEventsGrouped = groupBy(setDatesAsMonths(analyticsEventsFiltered), 'creation_date');
        break;
      default:
        break;
    }

    const getDataSetFromFiltered = () => {
      return {
        analyticsEventsLabels: getFilteredLabels(analyticsEventsFiltered),
        recordingStart: getDataSet(analyticsEventsFiltered, 'recording_start'),
        audioImport: getDataSet(analyticsEventsFiltered,'audio_import'),
        videoImport: getDataSet(analyticsEventsFiltered,'video_import'),
        songDisplay: getDataSet(analyticsEventsFiltered, 'song_display'),
        recordingPlusImport: getSumDataSet([ getDataSet(analyticsEventsFiltered, 'recording_start'), getDataSet(analyticsEventsFiltered,'audio_import'), getDataSet(analyticsEventsFiltered,'video_import') ]),
        analysisBegin: getDataSet(analyticsEventsFiltered, 'cloud_analysis_begin'),
        analysisUploadFinish: getDataSet(analyticsEventsFiltered, 'cloud_analysis_upload_finish'),
        analysisEnd: getDataSet(analyticsEventsFiltered, 'cloud_analysis_end'),
        analysisAborted: getDataSet(analyticsEventsFiltered, 'analysis_aborted'),
        snippetReady: getDataSet(analyticsEventsFiltered, 'snippet_ready'),
        songReady: getDataSet(analyticsEventsFiltered, 'song_ready'),
        chordAnalysisReady: getDataSet(analyticsEventsFiltered, 'chord_analysis_ready'),
        recordingStopTooShort: getDataSet(analyticsEventsFiltered, 'recording_stop_too_short'),
        analysisResultMissing: getDataSet(analyticsEventsFiltered, 'analysis_result_missing'),
        analysisAbortedCloudAnalysisBegin: getDataSet(analyticsEventsFiltered, 'analysis_aborted_cloud_analysis_begin'),
        analysisAbortedCloudUploadFinish: getDataSet(analyticsEventsFiltered, 'analysis_aborted_cloud_analysis_upload_finish'),
        analysisAbortedCloudAnalysisEnd: getDataSet(analyticsEventsFiltered, 'analysis_aborted_cloud_analysis_end'),
        analysisAbortedSnippetReady: getDataSet(analyticsEventsFiltered, 'analysis_aborted_snippet_ready'),
        analysisAbortedSongReady: getDataSet(analyticsEventsFiltered, 'analysis_aborted_song_ready'),
        analysisAbortedChordAnalysisReady: getDataSet(analyticsEventsFiltered, 'analysis_aborted_chord_analysis_ready'),
      };
    }

    const getDataSetFromFilteredAndGrouped = () => {
      return {
        analyticsEventsLabels: Object.keys(analyticsEventsGrouped).map(group => group),
        recordingStart: getDataSetGrouped(analyticsEventsGrouped, 'recording_start'),
        audioImport: getDataSetGrouped(analyticsEventsGrouped,'audio_import'),
        videoImport: getDataSetGrouped(analyticsEventsGrouped,'video_import'),
        songDisplay: getDataSetGrouped(analyticsEventsGrouped, 'song_display'),
        recordingPlusImport: getSumDataSet([ getDataSetGrouped(analyticsEventsGrouped, 'recording_start'), getDataSetGrouped(analyticsEventsGrouped,'audio_import'), getDataSetGrouped(analyticsEventsGrouped,'video_import') ]),
        analysisBegin: getDataSetGrouped(analyticsEventsGrouped, 'cloud_analysis_begin'),
        analysisUploadFinish: getDataSetGrouped(analyticsEventsGrouped, 'cloud_analysis_upload_finish'),
        analysisEnd: getDataSetGrouped(analyticsEventsGrouped, 'cloud_analysis_end'),
        analysisAborted: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted'),
        snippetReady: getDataSetGrouped(analyticsEventsGrouped, 'snippet_ready'),
        songReady: getDataSetGrouped(analyticsEventsGrouped, 'song_ready'),
        chordAnalysisReady: getDataSetGrouped(analyticsEventsGrouped, 'chord_analysis_ready'),
        recordingStopTooShort: getDataSetGrouped(analyticsEventsGrouped, 'recording_stop_too_short'),
        analysisResultMissing: getDataSetGrouped(analyticsEventsGrouped, 'analysis_result_missing'),
        analysisAbortedCloudAnalysisBegin: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_cloud_analysis_begin'),
        analysisAbortedCloudUploadFinish: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_cloud_analysis_upload_finish'),
        analysisAbortedCloudAnalysisEnd: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_cloud_analysis_end'),
        analysisAbortedSnippetReady: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_snippet_ready'),
        analysisAbortedSongReady: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_song_ready'),
        analysisAbortedChordAnalysisReady: getDataSetGrouped(analyticsEventsGrouped, 'analysis_aborted_chord_analysis_ready'),
      };
    }

    const getData = () => {
      if (group) return getDataSetFromFilteredAndGrouped();
      return getDataSetFromFiltered();
    }

    return getData();
  }

  const analysisSteps = data => {
    const labels = [
      'import+rec',
      'cloud begin',
      'upload finish',
      'cloud end',
      'snippet ready',
      'song ready',
      'chord ready',
      'song display',
      'manual abort'
    ];
    const analysisStepsValues = {
      'recordingPlusImport': getTotal(data.recordingPlusImport),
      'analysisBegin': getTotal(data.analysisBegin),
      'analysisUploadFinish': getTotal(data.analysisUploadFinish),
      'analysisEnd': getTotal(data.analysisEnd),
      'snippetReady': getTotal(data.snippetReady),
      'songReady': getTotal(data.songReady),
      'chordAnalysisReady': getTotal(data.chordAnalysisReady),
      'songDisplay': getTotal(data.songDisplay),
      'manualAbort': 0 // manualAbort is in 'analysisAbortedValues' array to show it in different color
    };
    const analysisAbortedValues = {
      'dummyDataFirtsStep': 0, // Add 0 that matches the 1st analysis step 'import + recordig' (aborted values start from second step 'cloud begin')
      'dummyDataRecordingTooShort': 0, // recordingTooShort is in it's own array to show it in different color
      'analysisAbortedCloudAnalysisBegin': getTotal(data.analysisAbortedCloudAnalysisBegin),
      'analysisAbortedCloudUploadFinish': getTotal(data.analysisAbortedCloudUploadFinish),
      'analysisAbortedCloudAnalysisEnd': getTotal(data.analysisAbortedCloudAnalysisEnd),
      'analysisAbortedSnippetReady': getTotal(data.analysisAbortedSnippetReady),
      'analysisAbortedSongReady': getTotal(data.analysisAbortedSongReady),
      'analysisAbortedChordAnalysisReady': getTotal(data.analysisAbortedChordAnalysisReady),
      'analysisAborted': getTotal(data.analysisAborted),
    };

    const analysisResultMissing = getTotal(data.analysisResultMissing);
    const recordingStopTooShort = getTotal(data.recordingStopTooShort);

    // adjust some step values
    analysisStepsValues.analysisBegin = analysisStepsValues.analysisBegin - recordingStopTooShort;
    analysisStepsValues.analysisUploadFinish = analysisStepsValues.analysisUploadFinish - recordingStopTooShort;
    analysisStepsValues.analysisUploadFinish = analysisStepsValues.analysisUploadFinish - analysisAbortedValues.analysisAbortedCloudAnalysisBegin;

    const total = analysisStepsValues.recordingPlusImport;
    const percentage = Object.values(analysisStepsValues).map(stepValue => Math.round(stepValue / total * 100));

    //Remove the last dummy 0 value and add analysisAborted instead
    percentage.pop();
    percentage.push(Math.round(analysisAbortedValues.analysisAborted / total * 100));

    const labelsWithPercentage = labels.map((label, i) => `${label} ${percentage[i]}%`);

    return {
      analysisStepsValues,
      analysisAbortedValues,
      analysisResultMissing,
      recordingStopTooShort,
      labels: labelsWithPercentage
    }
  }

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());  // remove time from date
  
  const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth()-6, now.getDate());
  sixMonthsAgo.setDate(sixMonthsAgo.getDate() - sixMonthsAgo.getDay()+1); // monday of daysAgo week
  const daysSinceSixMonthsAgo = Math.round((today - sixMonthsAgo) / (1000 * 60 * 60 * 24));

  const dataLast6Months = getDataFilteredAndGrouped(daysSinceSixMonthsAgo, 'week');
  const dataLast7Days = getDataFilteredAndGrouped(7);
  const dataLast30Days = getDataFilteredAndGrouped(30);

  const analysisStepsLast6Months = analysisSteps(dataLast6Months);
  const analysisStepsLast7Days = analysisSteps(dataLast7Days);
  const analysisStepsLast30Days = analysisSteps(dataLast30Days);

  const failed = dataLast6Months.recordingPlusImport.map((total, i) => total - dataLast6Months.songDisplay[i] - dataLast6Months.analysisAborted[i]);
  const analysisDetails = [[dataLast6Months.audioImport, dataLast6Months.videoImport, dataLast6Months.recordingStart], [dataLast6Months.songDisplay, dataLast6Months.analysisAborted, failed]];

  console.log(dataLast6Months.recordingPlusImport)
  console.log(analysisDetails)

  const addAnalisisAbortData = data => {
    const {analysisStepsValues, analysisAbortedValues, analysisResultMissing, recordingStopTooShort } = data;
    // create an array for missing results to show it in different color
    const analysisResultMissingValues = new Array(9).fill(0);
    analysisResultMissingValues[3] = analysisResultMissing;

    // create an array for recordingTooShort to show it in different color
    const recordingStopTooShortValues = new Array(9).fill(0);
    recordingStopTooShortValues[1] = recordingStopTooShort;

    return [
      Object.values(analysisStepsValues),
      Object.values(analysisAbortedValues),
      recordingStopTooShortValues,
      analysisResultMissingValues
    ]
  };

  return (
    <Page
      className='DashboardPage'
      title='Analysis Details'
    >
      <Row>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Analysis Started vs Result Last 6 Months (Stacked)'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[analysisStepsLast6Months.values]}
            hideAvg={true}
            hideTotal={true}
          >
            <HorizontalBar data={chartConfigMultiLinedStackedBar(
              analysisStepsLast6Months.labels,
              [['Events', 'Analysis aborted', 'Recording too short', 'Analysis missing']],
              [addAnalisisAbortData(analysisStepsLast6Months)],
              [[colors.green, colors.lightred, colors.babyblue, colors.orange]],
              FILL,
              STACKED)}
              options = {setOptionsBarMultiColored(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Analysis Started vs Result Last 6 Months (Stacked)'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[
              getTotal(dataLast6Months.recordingPlusImport),
              getTotal(dataLast6Months.songDisplay),
              getTotal(dataLast6Months.analysisAborted),
            ]}
            hideAvg={true}
            hideTotal={true}
          >
            <Bar data={chartConfigMultiLinedStackedBar(
              dataLast6Months.analyticsEventsLabels,
              [['Audio import', 'Video import', 'Recording'], ['Finished', 'Aborted', 'Failed']],
              analysisDetails,
              [[colors.lightblue, colors.variant_lightpurple, colors.lightred], [colors.green, colors.yellow, colors.darkred]],
              FILL,
              STACKED)}
              options = {setOptions(STACKED, STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Analysis Steps Last 30 Days'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[analysisStepsLast30Days.values]}
            hideAvg={true}
            hideTotal={true}
          >
            <HorizontalBar data={chartConfigMultiLinedStackedBar(
              analysisStepsLast30Days.labels,
              [['Events', 'Analysis aborted', 'Recording too short', 'Analysis missing']],
              [addAnalisisAbortData(analysisStepsLast30Days)],
              [[colors.green, colors.lightred, colors.babyblue, colors.orange]],
              FILL,
              STACKED)}
              options = {setOptionsBarMultiColored(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Analysis Steps Last 7 Days'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[analysisStepsLast7Days.values]}
            hideAvg={true}
            hideTotal={true}
          >
            <HorizontalBar data={chartConfigMultiLinedStackedBar(
              analysisStepsLast7Days.labels,
              [['Events', 'Analysis aborted', 'Recording too short', 'Analysis missing']],
              [addAnalisisAbortData(analysisStepsLast7Days)],
              [[colors.green, colors.lightred, colors.babyblue, colors.orange]],
              FILL,
              STACKED)}
              options = {setOptionsBarMultiColored(STACKED)}
            />
          </ChartCard>
        </Col>
        <Card className="m-3">
          <CardBody>
            <ul>
              <li className="mb-2">
                Inspelningar som avbryts under inräkning får inte `recording-start` eller `analysis-aborted`, men ett `recording-stop` med negativt duration. De kommer inte med i "too short recordings" i statistiken (men går att få fram som ett eget värde om man vill).
              </li>
              <li className="mb-2">
                Inspelningar som avbryts innan 8 sek får `recording-start` och `recording-stop`, men även `cloud-analysis-begin` och `cloud-analysis-upload-finish`. De får inte `analysis-aborted`. Dessa räknas i `recording_stop_too_short` i statistiken, och sållas bort från både `cloud-analysis-begin` och `cloud-analysis-upload-finish` i dashboarden.
              </li>
              <li>
                Inspelningar som avbryts efter `cloud-analysis-begin` och `cloud-analysis-upload-finish` får `analysis-aborted` med `step: cloud-analysis-begin` men får även ett event för `cloud-analysis-upload-finish` som sållas bort i dashboarden.
              </li>
            </ul>
          </CardBody>
        </Card>
      </Row>
    </Page>
  );
}

export default AnalysisDetailsPage;
