import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import { Card, Col, Row } from 'reactstrap';

const AuthPage = props => {

  if (localStorage.getItem('user')) {
    return <Redirect to='/'/>
  }

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          <AuthForm
            authState={STATE_LOGIN}
            setIsCurrentUserFetched={props.setIsCurrentUserFetched}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default AuthPage;
