import logoImage from 'assets/img/logo/sc-logo2.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import { http, getCurrentUserURL, loginURL } from 'servicies/http';

class AuthForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        values: {
          email: '',
          password: ''
        },
        errors: {
          email: '',
          password: '',
          isInvalid: false
        }
      };
    };

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({
      values: {
        ...this.state.values,
      [name]: value
      },
      errors: {
       ...this.state.errors,
       [name]: ''
      }
    });
  };

  handleSubmit = async event => {
    const { email, password } = this.state.values;
    event.preventDefault();

    try {
      await http.post( loginURL(), { user: email, password } );
      const { data: user } = await http.get( getCurrentUserURL() );
      this.props.setIsCurrentUserFetched(true);
      localStorage.setItem('user', JSON.stringify(user));
      window.location.reload();
    } catch (e) {
      this.setState({
        errors: {
         ...this.state.errors,
         isInvalid: true
        }
      });
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
    } = this.props;

    const { values : { email, password }, errors : { isInvalid }} = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4 bg-gradient-theme logo-container">
            <img
              src={logoImage}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input
            {...usernameInputProps}
            onChange={this.handleChange}
            value={email}
            name="email"
            invalid={isInvalid}
          />
          <FormFeedback>
            User not found
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input
            {...passwordInputProps}
            onChange={this.handleChange}
            value={password}
            name="password"
            invalid={isInvalid}
          />
          <FormFeedback>
            and/or invalid password.
          </FormFeedback>
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )}
        <hr />
        <Button
          type="submit"
          size="lg"
          className="bg-gradient-theme-left border-0"
          block>
          {this.renderButtonText()}
        </Button>
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  }
};

export default AuthForm;
