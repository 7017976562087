import React from 'react';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';

import Page from 'components/Page';
import ProductMedia from 'components/ProductMedia';

const numberOfVideos = 200;

const YoutubeImports = ({ youtubeData, errors }) => {

  const { topYoutubeImports, failedYoutubeImports } = youtubeData;

  return (
    <Page
      className='DashboardPage'
      title='YouTube Imports'
      breadcrumbs={[{ name: 'Last year' }]}
    >
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <div>Top {numberOfVideos} users (and analyses) per video</div>
            </CardHeader>
            <CardBody>
              {topYoutubeImports.data.slice(0, numberOfVideos).map(
                ({ video_id, users, count }) => {
                  return (
                    <ProductMedia
                      key={video_id}
                      image={`https://img.youtube.com/vi/${video_id}/default.jpg`}
                      title={video_id}
                      description={null}
                      right={users}
                      moreRight={`(${count})`}
                    />
                  )
                }
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <div>Started videos that did not display</div>
            </CardHeader>
            <CardBody>
              {failedYoutubeImports.data.filter(video => video.song_ready < 1).slice(0, numberOfVideos).map(
                ({ video_id, count }) => {
                  return (
                    <ProductMedia
                      key={video_id}
                      image={`https://img.youtube.com/vi/${video_id}/default.jpg`}
                      title={video_id}
                      description={null}
                      right={count}
                    />
                  )
                }
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

export default YoutubeImports;
