import logoImage from 'assets/img/logo/logo.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { MdDashboard, MdShowChart, MdFilterDrama, MdExitToApp } from 'react-icons/md';
import { FaYoutube } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

let username;
try {
  username = JSON.parse(localStorage.getItem('user'))['username'];
} catch (e) {
  console.error('No user in session.')
}
let navItems = [];
username && username === 'stakeholder' ? navItems = [
  { to: '/', name: 'kpi', exact: true, Icon: MdDashboard },
] : navItems = [
  { to: '/', name: 'home', exact: true, Icon: MdDashboard },
  { to: '/analysis-details', name: 'Analysis Details', exact: true, Icon: MdFilterDrama },
  { to: '/data/daily', name: 'Daily (90 days)', exact: true, Icon: MdShowChart },
  { to: '/data/weekly', name: 'Weekly (1 year)', exact: true, Icon: MdShowChart },
  { to: '/data/monthly', name: 'Monthly (1 year)', exact: true, Icon: MdShowChart },
  { to: '/youtube-imports', name: 'Youtube Imports', exact: true, Icon: FaYoutube },
  { to: '/kpi', name: 'kpi', exact: true, Icon: MdDashboard },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logoImage}
                width="40"
                height="35"
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                Dashboard
              </span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <Nav vertical>
            {[{ to: '/logout', name: 'logout', exact: true, Icon: MdExitToApp }].map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
