import React from 'react';
import PropTypes from 'utils/propTypes';

import { Media } from 'reactstrap';

import Typography from 'components/Typography';

const ProductMedia = ({ image, title, description, right, moreRight, ...restProps }) => {
  return (
    <Media {...restProps} style={{ alignItems: 'center' }}>
      <Media left>
        <a href={`https://www.youtube.com/watch?v=${title}`}>
          <Media
            object
            src={image}
            className="rounded mr-2 mb-2"
            style={{ width: 100, height: 56.25, objectFit: 'cover'}}
          />
        </a>
      </Media>
      <Media body className="overflow-hidden">
        <Media heading tag="h5" className="text-truncate">
          <a href={`https://www.youtube.com/watch?v=${title}`} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>{title}</a>
        </Media>
        {description === null ? (
          <></>
        ) : (
          <p className="text-muted text-truncate">{description}</p>
        )}
      </Media>
      <Media right className="right align-self-center">
        {right && typeof right === 'string' ? (
          <Typography type="h4">{right}</Typography>
        ) : (
          right
        )}
      </Media>
      <Media right className="more-right align-self-center">
        {moreRight && typeof moreRight === 'string' ? (
          <Typography type="h4">{moreRight}</Typography>
        ) : (
          moreRight
        )}
      </Media>
    </Media>
  );
};

ProductMedia.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  right: PropTypes.node,
  moreRight: PropTypes.node,
};

export default ProductMedia;
