import React from 'react';
import { Col, Row, } from 'reactstrap';

import Page from 'components/Page';
import ChartCard from 'components/Card/ChartCard';
import { Bar, Line } from 'react-chartjs-2';
import { setOptions, chartConfig } from 'utils/chartConfigs';
import { colors } from 'utils/colors';
import {
  getSumDataSet,
  getFilteredDataKPI,
  setDatesAsMonths,
  groupBy,
  getDataSetGrouped,
  getDataSetGroupedAvg
} from 'utils/dataManager';

const APP_STARTS_COUNT_CHART_LABEL = 'App starts';
const STACKED = true;
const FILL = true;

const now = new Date();
const endDate = new Date(now.getFullYear(), now.getMonth());  // last day of previous month
const startDate = new Date(now.getFullYear() - 1, now.getMonth() - 1); // first day of previous month last year

const daysSinceStartDate = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

const DashboardKPI = ({ title, analyticsData, errors }) => {
  const { subscriptionsTrialvsPaid, flagsPerDay, songsPerDay, analyticsEventsPerDay, usersPerDay, subscribersPerDay, usersPerMonth } = analyticsData;

  const flagsFiltered = getFilteredDataKPI(flagsPerDay, daysSinceStartDate);
  const flagsGroupedByMonth = groupBy(setDatesAsMonths(flagsFiltered), 'creation_date');
  const flagsLabels = Object.keys(flagsGroupedByMonth).map(month => month);

  const songsFiltered = getFilteredDataKPI(songsPerDay, daysSinceStartDate);
  const songsGroupedByMonth = groupBy(setDatesAsMonths(songsFiltered), 'creation_date');
  const songsLabels = Object.keys(songsGroupedByMonth).map(month => month);

  const analyticsEventsFiltered = getFilteredDataKPI(analyticsEventsPerDay, daysSinceStartDate);
  const analyticsEventsGroupedByMonth = groupBy(setDatesAsMonths(analyticsEventsFiltered), 'creation_date');
  const analyticsEventsLabels = Object.keys(analyticsEventsGroupedByMonth).map(month => month);

  const usersFiltered = getFilteredDataKPI(usersPerDay, daysSinceStartDate);
  const usersGroupedByMonth = groupBy(setDatesAsMonths(usersFiltered), 'creation_date');
  const usersLabels = Object.keys(usersGroupedByMonth).map(month => month);

  const usersMyScorecloud = getDataSetGrouped(usersGroupedByMonth, 'registration_app_myscorecloud');
  const usersStudio = getDataSetGrouped(usersGroupedByMonth, 'registration_app_studio');
  const usersExpress = getDataSetGrouped(usersGroupedByMonth, 'registration_app_express');
  const usersUnknown = getDataSetGrouped(usersGroupedByMonth, 'registration_app_unknown');
  const usersSongwriter = getDataSetGrouped(usersGroupedByMonth, 'registration_app_songwriter');

  const subscribersFiltered = getFilteredDataKPI(subscribersPerDay, daysSinceStartDate);
  const subscribersGroupedByMonth = groupBy(setDatesAsMonths(subscribersFiltered), 'creation_date');
  const subscribersTimeLabels = Object.keys(subscribersGroupedByMonth).map(month => month);
  // const subscribersTrialPlus = getSumDataSet([
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_plus_monthly'),
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_plus_yearly')
  // ]);
  // const subscribersTrialSongwriter = getSumDataSet([
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_songwriter_monthly'),
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_songwriter_yearly')
  // ]);
  // const subscribersTrialPro = getSumDataSet([
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_pro_monthly'),
  //   getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_trial_pro_yearly'),
  // ]);
  const subscribersPaidPlus = getSumDataSet([
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_plus_monthly'),
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_plus_yearly'),
  ]);
  const subscribersPaidSongwriter = getSumDataSet([
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_songwriter_monthly'),
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_songwriter_yearly')
  ]);
  const subscribersPaidPro = getSumDataSet([
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_pro_monthly'),
    getDataSetGroupedAvg(subscribersGroupedByMonth, 'subscribers_paid_pro_yearly')
  ]);
  const activeUsersStudio = getDataSetGroupedAvg(subscribersGroupedByMonth, 'active_users_studio');
  const activeUsersSongwriter = getDataSetGroupedAvg(subscribersGroupedByMonth, 'active_users_songwriter');
  const activeUsersExpress = getDataSetGroupedAvg(subscribersGroupedByMonth, 'active_users_express');

  const newSongwriterSongs = getDataSetGrouped(songsGroupedByMonth, 'appname_songwriter');
  const newStudioSongs = getDataSetGrouped(songsGroupedByMonth, 'appname_studio');
  const newExpressSongs = getDataSetGrouped(songsGroupedByMonth, 'source_type_recording');
  // const newStudioAndSongwriterSongs = getDataSetGrouped(songsGroupedByMonth, 'source_type_manual');

  const paidSubscriptionSongwriter = getDataSetGrouped(flagsGroupedByMonth, 'subscription_paid_songwriter');
  const trialSubscriptionSongwriter = getDataSetGrouped(flagsGroupedByMonth, 'subscription_trial_songwriter');
  const paidSubscriptionPlus = getDataSetGrouped(flagsGroupedByMonth, 'subscription_paid_plus');
  const trialSubscriptionPlus = getDataSetGrouped(flagsGroupedByMonth, 'subscription_trial_plus');
  const paidSubscriptionPro = getDataSetGrouped(flagsGroupedByMonth, 'subscription_paid_pro');
  const trialSubscriptionPro = getDataSetGrouped(flagsGroupedByMonth, 'subscription_trial_pro');

  const loginSongwriter = getDataSetGrouped(flagsGroupedByMonth, 'login_songwriter');
  const loginSongwriterWin = getDataSetGrouped(flagsGroupedByMonth, 'login_songwriter_win');
  const loginSongwriterOsx = getDataSetGrouped(flagsGroupedByMonth, 'login_songwriter_osx');
  const loginSongwriterOsxAppstore = getDataSetGrouped(flagsGroupedByMonth, 'login_songwriter_osx_appstore');

  const loginStudio = getDataSetGrouped(flagsGroupedByMonth, 'login_studio');
  const loginStudiorWin = getDataSetGrouped(flagsGroupedByMonth, 'login_studio_win');
  const loginStudioOsx = getDataSetGrouped(flagsGroupedByMonth, 'login_studio_osx');
  const loginStudioOsxAppstore = getDataSetGrouped(flagsGroupedByMonth, 'login_studio_osx_appstore');

  const loginExpress = getDataSetGrouped(flagsGroupedByMonth, 'login_express');
  const loginExpressIpad = getDataSetGrouped(flagsGroupedByMonth, 'login_express_ipad');
  const loginExpressIphone = getDataSetGrouped(flagsGroupedByMonth, 'login_express_iphone');
  const loginMyScorecloud = getDataSetGrouped(flagsGroupedByMonth, 'login_my_scorecloud');

  const programStartSongwriter = getDataSetGrouped(analyticsEventsGroupedByMonth, 'program_start');

  // const recordingStart = getDataSetGrouped(analyticsEventsGroupedByMonth, 'recording_start');
  // const audioImport = getDataSetGrouped(analyticsEventsGroupedByMonth,'audio_import');
  // const songDisplay = getDataSetGrouped(analyticsEventsGroupedByMonth, 'song_display');
  // const recordingPlusImport = getSumDataSet([ recordingStart, audioImport ]);

  const webDownloadSongwriterMac = getDataSetGrouped(analyticsEventsGroupedByMonth, 'web_download_songwriter_mac');
  const webDownloadSongwriterWin = getDataSetGrouped(analyticsEventsGroupedByMonth, 'web_download_songwriter_windows');
  const webDownloadStudioMac = getDataSetGrouped(analyticsEventsGroupedByMonth, 'web_download_studio_mac');
  const webDownloadStudioWin = getDataSetGrouped(analyticsEventsGroupedByMonth, 'web_download_studio_windows');

  const subscriptionChargedSuccessfullyPlusSum = getDataSetGrouped(analyticsEventsGroupedByMonth, 'subscription_charged_successfully_plus_sum');
  const subscriptionChargedSuccessfullyProSum = getDataSetGrouped(analyticsEventsGroupedByMonth, 'subscription_charged_successfully_pro_sum');
  const subscriptionChargedSuccessfullySongwriterSum = getDataSetGrouped(analyticsEventsGroupedByMonth, 'subscription_charged_successfully_songwriter_sum');
  
  const activeUsersValues = [
    activeUsersStudio,
    activeUsersSongwriter,
    activeUsersExpress
  ];

  const activeUsersLabels = ['Studio', 'Songwriter', 'Express'];

  const subscriptionChargedSuccessfullyPlanLabels = [
    'Plus (USD)',
    'Songwriter (USD)',
    'Pro (USD)'
  ];

  const subscriptionChargedSuccessfullyPlanValues = [
    subscriptionChargedSuccessfullyPlusSum,
    subscriptionChargedSuccessfullySongwriterSum,
    subscriptionChargedSuccessfullyProSum
  ];

  const webDownloadLabels = [
    'Songwriter Mac',
    'Songwriter Win',
    'Studio Mac',
    'Studio Win'
  ];

  const webDownloadValues = [
    webDownloadSongwriterMac,
    webDownloadSongwriterWin,
    webDownloadStudioMac,
    webDownloadStudioWin
  ];

  const newSubscriptionsLabels = [
    'Trial Plus',
    'Paid Plus',
    'Trial Songwriter',
    'Paid Songwriter',
    'Trial Pro',
    'Paid Pro'
  ];
  const newSubscriptionsValues = [
    trialSubscriptionPlus,
    paidSubscriptionPlus,
    trialSubscriptionSongwriter,
    paidSubscriptionSongwriter,
    trialSubscriptionPro,
    paidSubscriptionPro,
  ];
  const firstTimeLoginLabels = [
    'My Scorecloud',
    'Studio',
    'Studio Win',
    'Studio Osx',
    'Studio Osx Appstore',
    'Songwriter',
    'Songwriter Win',
    'Songwriter Osx',
    'Songwriter Osx Appstore',
    'Express',
    'Express iPad',
    'Express iPhone'
  ];
  const firstTimeLoginValues = [
    loginMyScorecloud,
    loginStudio,
    loginStudiorWin,
    loginStudioOsx,
    loginStudioOsxAppstore,
    loginSongwriter,
    loginSongwriterWin,
    loginSongwriterOsx,
    loginSongwriterOsxAppstore,
    loginExpress,
    loginExpressIpad,
    loginExpressIphone
  ];

  const expectedExpressRevenueLabels = [
    'Unknown (USD)',
    'iPad (USD)',
    'iPhone (USD)'
  ];
  const expectedExpressRevenue = [];
  const expectedExpressRevenueIpad = [];
  const expectedExpressRevenueIphone = [];
  for (let i=0; i < loginExpressIpad.length; i++) {
    expectedExpressRevenue[i] = Math.round(loginExpress[i] * 2.99);
    expectedExpressRevenueIpad[i] = Math.round(loginExpressIpad[i] * 2.99);
    expectedExpressRevenueIphone[i] = Math.round(loginExpressIphone[i] * 2.99);
  }
  const expectedExpressRevenueValues = [
    expectedExpressRevenue,
    expectedExpressRevenueIpad,
    expectedExpressRevenueIphone
  ];

  const newSongsPerClientLabels = ['Studio', 'Songwriter', 'Express'];
  const newSongsPerClientValues = [newStudioSongs, newSongwriterSongs, newExpressSongs];

  // const newSongsLabels = ['Desktop', 'Express'];
  // const newSongsValues =[newStudioAndSongwriterSongs, newExpressSongs];

  // const newSongsByAppNameLabels = ['Studio', 'Songwriter'];
  // const newSongsByAppNameValues = [newStudioSongs, newSongwriterSongs];

  // const recordingPlusImportLabels = ['Recordings', 'Imports'];
  // const recordingPlusImportValues = [recordingStart, audioImport];

  // const finishedVsStartedLabels = ['Finished', 'Started'];
  // const finishedVsStartedValues = [songDisplay, recordingPlusImport];

  const subscriptionsTrialVsPaid = [subscriptionsTrialvsPaid.dataTrial, subscriptionsTrialvsPaid.dataPaid];

  const newUsersLabels = [
    'My Scorecloud',
    'Studio',
    'Songwriter',
    'Express',
    'Unknown'
  ];
  const newUsersValues = [
    usersMyScorecloud,
    usersStudio,
    usersSongwriter,
    usersExpress,
    usersUnknown
  ];

  const subscribersLabels = [
    'Paid Plus',
    'Paid Songwriter',
    'Paid Pro'
  ];

  const subscribersValues = [
    subscribersPaidPlus,
    subscribersPaidSongwriter,
    subscribersPaidPro
  ];

  let usersPerMonthKPI = usersPerMonth.data.slice(-14,-1);

  const usersPerMonthTimeLabels = setDatesAsMonths(usersPerMonthKPI).map(data => { return data.creation_date });

  const usersPerMonthLabels = [
    'Users with Analyis Start',
    'Users with Cloud Save',
    'Users with Song Display'
  ]

  const usersPerMonthValues = [
    usersPerMonthKPI.map(data => { return [ data.analysis_start_users ] }),
    usersPerMonthKPI.map(data => { return [ data.cloud_save_users ] }),
    usersPerMonthKPI.map(data => { return [ data.song_display_users ] }),
  ];

  return (
    <Page
      className='DashboardPage'
      title='Dashboard'
      breadcrumbs={[{ name: title, active: true }]}
    >
      <Row>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Current Subscription Count'
            loading={subscriptionsTrialvsPaid.loading}
            error={errors.subscriptionsTrialvsPaid}
            values={subscriptionsTrialVsPaid}
            description="Number of active subscriptions right now"
            hideAvg={true}
          >
            <Bar data={chartConfig(
              subscriptionsTrialvsPaid.subscriptionNames,
              ['Trial', 'Paid'],
              subscriptionsTrialVsPaid,
              [colors.primary, colors.secondary])} />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscribers over time'
            loading={subscribersPerDay.loading}
            error={errors.subscribersPerDay}
            values={subscribersValues}
            description="Snapshot of active subscriptions over time"
            hideTotal={true}
          >
            <Line
              data={chartConfig(
                subscribersTimeLabels,
                subscribersLabels,
                subscribersValues,
                [
                  colors.teal,
                  colors.yellow,
                  colors.purple
                ]
              )}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Monthly Active Users - ScoreCloud Songwriter'
            loading={usersPerMonth.loading}
            error={errors.usersPerMonth}
            values={usersPerMonthValues}
            description="Unique users per month with important action in the ScoreCloud Songwriter client"
            hideTotal={true}
          >
            <Line
              data={chartConfig(
                usersPerMonthTimeLabels,
                usersPerMonthLabels,
                usersPerMonthValues,
                [
                  colors.teal,
                  colors.yellow,
                  colors.purple
                ]
              )}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='30 Day Active Users (Stacked)'
            loading={subscribersPerDay.loading}
            error={errors.subscribersPerDay}
            values={activeUsersValues}
            hideTotal={true}
            description="Number of users active for 30 days"
          >
            <Line
              data={chartConfig(
                subscribersTimeLabels,
                activeUsersLabels,
                activeUsersValues,
                [
                  colors.variant_lightblue,
                  colors.variant_lightyellow,
                  colors.variant_lightred,
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Registered Users per Client (Stacked)'
            loading={usersPerDay.loading}
            error={errors.usersPerDay}
            values={newUsersValues}
            description="Number of new registered users, and from where they registered"
          >
            <Line
              data={chartConfig(
                usersLabels,
                newUsersLabels,
                newUsersValues,
                [
                  colors.variant_lightgreen,
                  colors.variant_lightblue,
                  colors.variant_lightyellow,
                  colors.variant_lightred,
                  colors.variant_lightpurple
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Subscriptions, Trial & Paid (Stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={newSubscriptionsValues}
            description="This triggers the first time a user starts a specific plan, so unsubbing and re-subbing to the same plan is not included, but switching plans is included"
          >
            <Line
              data={chartConfig(
                flagsLabels,
                newSubscriptionsLabels,
                newSubscriptionsValues,
                [
                  colors.variant_lightgreen,
                  colors.calm_darkgreen,
                  colors.variant_lightyellow,
                  colors.calm_darkyellow,
                  colors.variant_lightpurple,
                  colors.calm_darkpurple
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time login (stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={firstTimeLoginValues}
            description="Number of users logging in to a client for the first time (same user can appear in multiple)"
          >
            <Line
              data={chartConfig(
                flagsLabels,
                firstTimeLoginLabels,
                firstTimeLoginValues,
                [
                  colors.grey2,
                  colors.green,
                  colors.darkgreen,
                  colors.teal,
                  colors.darkteal,
                  colors.yellow,
                  colors.darkyellow,
                  colors.orange,
                  colors.darkorange,
                  colors.lightblue,
                  colors.blue,
                  colors.darkblue

                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Revenue from successfully charged subscriptions (Stacked)'
            description="Sum of money paid to Doremir from ScoreCloud subscriptions per plan (in USD from Braintree)"
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={subscriptionChargedSuccessfullyPlanValues}
          >
            <Line
              data={chartConfig(
                analyticsEventsLabels,
                subscriptionChargedSuccessfullyPlanLabels,
                subscriptionChargedSuccessfullyPlanValues,
                [
                  colors.calm_darkgreen,
                  colors.calm_darkyellow,
                  colors.calm_darkpurple
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Estimated Revenue From ScoreCloud Express Installs'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={expectedExpressRevenueValues}
            description="Number of users doing their first login in ScoreCloud Express × App Price ($2.99). Unknowns are social logins where we can't see OS here."
          >
            <Line
              data={chartConfig(
                flagsLabels,
                expectedExpressRevenueLabels,
                expectedExpressRevenueValues,
                [colors.lightblue, colors.blue, colors.darkblue],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Songs Saved in the Cloud Per Client (Stacked)'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={newSongsPerClientValues}
            description="Note: ScoreCloud Express auto-saves a song in the cloud after recording, while desktop apps requires manual save. Also PRO users can save songs to disk, which is not included here."
          >
            <Line
              data={chartConfig(
                songsLabels,
                newSongsPerClientLabels,
                newSongsPerClientValues,
                [colors.variant_lightblue, colors.variant_lightyellow, colors.variant_lightred],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter app starts'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[programStartSongwriter]}
          >
            <Line
              data={chartConfig(
                analyticsEventsLabels,
                [APP_STARTS_COUNT_CHART_LABEL],
                [programStartSongwriter],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Web Downloads (Stacked)'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={webDownloadValues}
          >
            <Line
              data={chartConfig(
                analyticsEventsLabels,
                webDownloadLabels,
                webDownloadValues,
                [
                  colors.yellow,
                  colors.darkyellow,
                  colors.teal,
                  colors.darkteal
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
      </Row>
    </Page>
  );
}

export default DashboardKPI;
