const footer = (tooltipItems) => {
  let footer = [];
  const sum = tooltipItems.reduce((current, next) => current + next.yLabel, 0);

  if (tooltipItems.length === 2) {
    const a = Math.max(tooltipItems[0].yLabel, tooltipItems[1].yLabel);
    const b = Math.min(tooltipItems[0].yLabel, tooltipItems[1].yLabel);
    let BofA = (a && b ? ((b / a) * 100).toFixed(0) + '%' : '-');
    let AofSum = (sum > 0 ? ((a / sum) * 100).toFixed(0) + '%' : '-');
    footer.push('Low/High: ' + BofA);
    footer.push('High/Sum: ' + AofSum);
  }

  if (tooltipItems.length > 1)
    footer.push('Sum: ' + sum);

  return footer ? footer : '';
};

export const setOptions = (stacked = false, stackedX = false, customHeight = false, multiAxis = false) => {
  return ({
    maintainAspectRatio: !customHeight,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        footer
      }
    },
    scales: {
      xAxes: [{
        stacked: stackedX,
      }],
      yAxes: [
        {
          id: 'y',
          type: 'linear',
          position: 'left',
          stacked,
          ticks: {
            beginAtZero: true,
            min: 0
          },
        },
        {
          id: 'y1',
          type: 'linear',
          display: multiAxis,
          position: 'right',
          stacked,
          ticks: {
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            display: false, // only want the grid lines for one axis to show up
          },
        }
      ],
    },
    animation: {
      duration: 0
    }
  });
}

export const setOptionsBarMultiColored = (stackedX = false) => {
  return {
    scales: {
      xAxes: [{
        stacked: stackedX,
        ticks: {
          beginAtZero: true,
          min: 0
        },
      }]
    }
  };
}

const getDataSet = (label, data, adjustedColors, colors, stacked, fill, yAxisID) => ({
  label,
  data,
  backgroundColor: adjustedColors,
  borderColor: stacked ? '#ffffff' : colors,
  borderWidth: stacked ? 0.5 : 1.8,
  pointRadius: stacked ? 0 : 1,
  lineTension: stacked ? 0.1 : 0.3,
  fill,
  yAxisID
});

const ajustColors = (colors, stacked) => colors.map(color => color + (stacked ? 'ff' : '80'));

export const chartConfig = (labels, dataSetsLabels, dataSetsData, colors, fill = false, stacked = false) => {
  const adjustedColors = ajustColors(colors, stacked);
  const datasets = dataSetsLabels.map((label, i) => getDataSet(label, dataSetsData[i], adjustedColors[i], colors[i], stacked, fill));
  return {
    labels,
    datasets
  }
};

export const chartConfigLineMultiAxis = (labels, dataSetsLabels, dataSetsData, colors, fill = false, stacked = false, yAxisID) => {
  const adjustedColors = ajustColors(colors, stacked);
  const datasets = dataSetsLabels.map((label, i) => getDataSet(label, dataSetsData[i], adjustedColors[i], colors[i], stacked, fill, yAxisID[i]));
  return {
    labels,
    datasets
  }
};

export const chartConfigMultiLinedStackedBar = (labels, dataSetsLabels, dataSetsData, colors, fill = false, stacked = false) => {
  const datasets = dataSetsLabels
    .map((label, i) => chartConfig(labels, label, dataSetsData[i], colors[i], fill, stacked))
    .map(chartConfig => chartConfig.datasets)
    .map((dataset, i) => dataset.map(data => ({...data, stack: i})))
    .flat(1);
  return {
    labels,
    datasets
  }
};

export const chartConfigBarMultiColored = (labels, dataSetsLabels, dataSetsData, colors, fill = false, stacked = false) => {
  const adjustedColors = ajustColors(colors, stacked);
  const datasets = dataSetsLabels.map((label, i) => getDataSet(label, dataSetsData[i], adjustedColors, colors, stacked, fill));
  return {
    labels,
    datasets
  }
};


