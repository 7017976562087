import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ isCurrentUserFetched, ...rest }) => {
  const privatRoute = localStorage.getItem('user')
    ? <Route {...rest} />
    : <Route render={({ location }) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    />
  return privatRoute;
};

export default PrivateRoute;