import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const getDaysAgoData = (data, daysAgo) => {
  let currentDate = new Date();
  let daysAgoDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - daysAgo));
  return data.filter(item => new Date(item.creation_date) >= daysAgoDate)
             .sort((a, b) => a.creation_date.toString().localeCompare(b.creation_date.toString()));
};

const getDaysAgoDataKPI = (data, daysAgo) => {
  const now = new Date();
  let lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth());  // last day of previous month
  let daysAgoDate = new Date(Date.UTC(lastDayOfPreviousMonth.getFullYear(), lastDayOfPreviousMonth.getMonth(), lastDayOfPreviousMonth.getDate() - daysAgo)); // first day of previous month last year
  return data.filter(item => new Date(item.creation_date) >= daysAgoDate && new Date(item.creation_date) <= lastDayOfPreviousMonth)
             .sort((a, b) => a.creation_date.toString().localeCompare(b.creation_date.toString()));
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
  'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const getLabels = data => data.map(dataItem => {
  const date = new Date(parseInt(dataItem.creation_date));
  return `${date.getDate()} ${monthNames[date.getMonth()]}`;
});

export const getDataSet = (data, key) => {
  return data
    .map(item => item[key])
    .map(count => count || 0);
};

const getDataSetSum = (data, key) => {
  return getDataSet(data, key)
    .reduce((a,b) => a + parseInt(b), 0);
};

const getDataSetAvg = (data, key) => {
  const dataSet = getDataSet(data, key);
  return Math.round(dataSet.reduce((a,b) => a + parseInt(b), 0) / dataSet.length);
};

const getYearOfIsoWeek = (creationDate) => {
  // Returns the four-digit year corresponding to the ISO week of the date.
  let date = new Date(creationDate);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
};

export const getDataSetGroupedAvg = (groupedData, key) => {
  return Object.values(groupedData).map(group => getDataSetAvg(group, key));
};

export const getDataSetGrouped = (groupedData, key) => {
  return Object.values(groupedData).map(group => getDataSetSum(group, key));
};

export const getSumDataSet = (matrix) => matrix.reduce((a, b) => a.map((x, i) => parseInt(x) + parseInt(b[i])));

export const getTotal = dataSet => {
  if (dataSet && !dataSet.length) return 0;
  return dataSet.flat().reduce((a,b) => a + parseInt(b), 0);
};

export const getAvg = (dataSet) => {
  if (dataSet && (!dataSet.length || !dataSet[0] || !dataSet[0].length)) return 0;
  return Math.round(dataSet.flat().reduce((a,b) => a + parseInt(b), 0) / dataSet[0].length);
};

export const getFilteredData = (dataPerDay, daysAgo) => {
  if (dataPerDay.data && dataPerDay.data.length)  return getDaysAgoData(dataPerDay.data, daysAgo);
  return [];
};

export const getFilteredDataKPI = (dataPerDay, daysAgo) => {
  if (dataPerDay.data && dataPerDay.data.length)  return getDaysAgoDataKPI(dataPerDay.data, daysAgo);
  return [];
};

export const getFilteredLabels = (dataFiltered) => {
  if (dataFiltered.length) return getLabels(dataFiltered);
  return [];
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const setDatesAsWeeks = (data, daysAgo) => {
  if (daysAgo > 365) return data.map(item => ({ ...item, creation_date: `Week ${dayjs(new Date(item.creation_date)).isoWeek()} ${getYearOfIsoWeek(item.creation_date)}`}));
  return data.map(item => ({ ...item, creation_date: `Week ${dayjs(new Date(item.creation_date)).isoWeek()}`}));
};

export const setDatesAsMonths = data => {
  return data.map(item => ({ ...item, creation_date: `${monthNames[dayjs(new Date(item.creation_date)).month()]} ${dayjs(new Date(item.creation_date)).year()}` }));
};
