import axios from 'axios';

export const getCurrentUserURL = () => '/current-user/info';
export const loginURL = () => '/login';
export const getSubscriptionTrialvsPaidURL = () => '/admin/stats/subscriptions-trial-vs-paid';
export const getFlagsPerDayURL = () => '/admin/stats/flags-per-day';
export const getSongsPerDayURL = () => '/admin/stats/songs-per-day';
export const getAnalyticsEventsPerDayURL = () => '/admin/stats/analytics-events-per-day';
export const getUsersPerDayURL = () => '/admin/stats/users-per-day';
export const getUsersPerMonthURL = () => '/admin/stats/users-per-month';
export const getSubscribersPerDayURL = () => '/admin/stats/subscribers-per-day';
export const getTopYoutubeImportsURL = () => '/admin/stats/top-youtube-imports';
export const getFailedYoutubeImportsURL = () => '/admin/stats/failed-youtube-imports';

export const http = axios.create({
  withCredentials: true,
  baseURL: '/api/2.0/',
});